#blog {
  padding: 140px 0 20px 0;
}

.blog-nav {
  margin: 5px 10px;
  padding: 0;
}

.blog-nav a {
  display: inline-block;
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  color: #555;
  font-size: 15px;
  font-weight: 400;
  padding: 5px 15px;
  border-radius: 15px;
  margin-bottom: 5px;
  cursor: pointer;
}

.blog-nav a.active,
.blog-nav a:hover,
.blog-nav a:focus {
  color: #fff;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
}