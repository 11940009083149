#blog-container {
  margin: 0 auto;
  max-width: 744px;
  font-family: sans-serif;
  line-height: 32px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.7);
  font-size: 21px;
  border-top: 2px solid #E5E5E5;
  padding-bottom: 20px;
}

@media (max-width: 768px) {
  #blog-container {
    padding: 0 20px;
  }
}

#blog-container h1,
#blog-container h2,
#blog-container h3 {
  font-family: "Raleway", sans-serif;
}

#blog-container h1 a,
#blog-container h1 a:visited {
  color: inherit;
  text-decoration: none;
}

#blog-container h1 {
  line-height: 38px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  font-size: 32px;
  margin: 32px 0 20px;
  text-align: center;
}

#blog-container h2 {
  line-height: 32px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  font-size: 26px;
  margin: 28px 0;
}

#blog-container h3 {
  line-height: 28px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  font-size: 21px;
  margin: 24px 0;
}

#blog-container p {
  margin: 20px 0;
}

#blog-container figcaption {
  font-size: 16px;
  font-style: italic;
}

#blog-container .created,
#blog-container .published {
  color: rgba(0, 0, 0, 0.55);
  font-size: 15px;
  line-height: 15px;
  margin: 20px 0;
}

#blog-container .created+.published {
  margin-top: -12px;
}

#blog-container blockquote {
  font-family: Georgia, Source Serif Pro, serif;
  font-style: italic;
  font-size: 24px;
  line-height: 36px;
  margin: 48px 120px;
  text-align: center;
}

#blog-container a {
  word-wrap: break-word;
  outline: none;
  text-decoration: none;
  background-color: transparent;
  border: 0;
  color: #008CC9;
}

#blog-container a:hover {
  text-decoration: underline;
}

#blog-container a:visited {
  color: #8C68CB;
}

#blog-container .center {
  text-align: center;
}

#blog-container iframe {
  display: block;
  margin: 44px auto;
}

#blog-container *:not(pre)+pre,
#blog-container pre:first-of-type {
  margin-top: 32px;
  padding-top: 32px;
}

#blog-container pre:only-of-type {
  margin: 32px 0;
  padding: 32px;
}

#blog-container pre {
  background: #F3F6F8;
  overflow-x: auto;
  display: block;
  font-size: 13px;
  font-family: monospace;
  line-height: 13px;
  padding: 0 32px 32px;
  white-space: pre;
}

#blog-container a.embedded {
  background: #F3F6F8;
  display: block;
  padding: 32px;
  margin: 32px 0;
}

#blog-container img {
  height: auto;
  max-width: 100%;
}

#blog-container .slate-image-embed__resize-full-width img {
  width: 100%;
}

#blog-container .series-logo {
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 6px;
  object-fit: scale-down;
  float: left;
}

#blog-container .series-title {
  font-size: 16px;
  font-weight: 600;
  vertical-align: top;
}

#blog-container .series-description {
  color: rgba(0, 0, 0, .6);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

#blog-container div {
  margin: 32px 0;
}